﻿$primary-blue: #0F65CE;
$primary-grey: #171717;
$secondary-grey: #0E0E0E;
$product-box-grey: #1D1D1D;
$price-grey: #333333;
$price-blue: #0950a7;
$text-color: #FFFFFF;
$free-trial-color: #228b22;

$email-color: #D65338;
$sms-color: #1EE01B;
$search-color: #FFBE00;
$discord-color: #6F69F1;
$telegram-color: #139EE5;
$shop-color: #ED9024;
$coming-soon-color: #3279CF;