@import './assets/colors.scss';
@import 'primeflex/primeflex.scss';
@import "primeicons/primeicons.css";

/* You can add global styles to this file, and also import other style files */
body {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='70' height='8' patternTransform='scale(2) rotate(145)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0, 0%, 9%, 1)'/><path d='M-.02 22c8.373 0 11.938-4.695 16.32-9.662C20.785 7.258 25.728 2 35 2c9.272 0 14.215 5.258 18.7 10.338C58.082 17.305 61.647 22 70.02 22M-.02 14.002C8.353 14 11.918 9.306 16.3 4.339 20.785-.742 25.728-6 35-6 44.272-6 49.215-.742 53.7 4.339c4.382 4.967 7.947 9.661 16.32 9.664M70 6.004c-8.373-.001-11.918-4.698-16.3-9.665C49.215-8.742 44.272-14 35-14c-9.272 0-14.215 5.258-18.7 10.339C11.918 1.306 8.353 6-.02 6.002'  stroke-width='0.5' stroke='hsla(0, 0%, 5%, 1)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(-32,-6)' fill='url(%23a)'/></svg>");
  font-family: RobotoMono;
  padding: 10px;
}

@font-face {
  font-family: RobotoMono;
  src: url(assets/fonts/RobotoMono-SemiBold.ttf) format('truetype');
}

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.content {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 41px;
  color: white; 
  text-align: center;
}

h2 {
  color: white;
  font-size: 28px;
  margin-bottom: 20px;
}

a {
  display: flex;
  list-style-type: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  width: auto;
  padding: 4px 10px;
}

p, h3 {
  color: #AAAAAA;
}

h4 {
  color: $primary-blue;
}

.highlight {
  color: $primary-blue;
  font-weight: bold;
}

.white-highlight {
  color: white;
  font-weight: bold;
}

.get-started {
  background-color: rgba(15, 101, 206, 35%);
  border: none;
  height: 40px;
  width: 135px;
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.19);

  cursor: pointer;
}

.attack-hub {
  width: 350px;
}

.splitter {
  width: 100%;
  height: 1px;
  background-color: white;
  opacity: 0.5;
  border-radius: 1px;
  margin-top: 30px;
}

.info-box {
  width: 18rem;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-box {
  width: 45%;
  height: 120px;
  background-color: $product-box-grey;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  cursor: pointer;
}

.product-box-best {
  width: 45%;
  height: 120px;
  background-color: rgba(15, 101, 206, 0.25);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.6px);
  -webkit-backdrop-filter: blur(10.6px);
  border: 1px solid rgba(15, 101, 206, 0.44);
  border-radius: 20px;
  cursor: pointer;
}

.product-box-price {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width:7rem;
  border: 1x solid $secondary-grey;
  border-radius: 20px;
  background-color: $price-grey; 
}

.product-box-price-best {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width:7rem;
  border-radius: 20px;
  background-color: $price-blue;
}

.get-started:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 0px 15px rgba(255, 255, 255, .4);
}

.product-box:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 0px 40px rgba(255, 255, 255, .4);
}

.product-box-best:hover {
  color: white;
  box-shadow: 0 0px 50px rgba(9, 80, 167, .7);
}